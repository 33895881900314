import React, { FunctionComponent, useState, useEffect } from "react";
import Page from "../components/Page";
import { indberetningsFristdato } from "../utils/AppConst";
import { ManglendeIndberetningsdata } from "../helpers/EleverTyper";
import ApiService from "../services/ApiService";
import PageLoader from "../components/PageLoader";
import { Side, MenuSetup } from "../helpers/RouteSetup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileDownloadService from "../services/FileDownloadService";
import FunktionsLink from "../components/common/FunktionsLink";
import { useAuthenticationStore } from "../context/store";

const IndberetningKvittering: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const [manglendeIndberetningsdata, setManglendeIndberetningsdata] =
        useState<ManglendeIndberetningsdata[] | undefined>();
    const [error, setError] = useState<Error | undefined>(undefined);

    useEffect(() => {
        async function callApi() {
            ApiService<ManglendeIndberetningsdata[]>(
                "institution/" +
                    authenticationStore.aktivInstitution.institutionsnummer +
                    "/hentkvittering/" +
                    authenticationStore.getSkoleAar()
            )
                .then((response) => {
                    setManglendeIndberetningsdata(response);
                })
                .catch((e) => {
                    setError(e);
                });
        }
        callApi();
    }, [
        authenticationStore.authenticatedUser,
        authenticationStore.aktivInstitution,
        authenticationStore.skoleAar,
    ]);

    function renderManglendeElever(data: ManglendeIndberetningsdata[]) {
        const elever0 = Array<object>();
        const elever19 = Array<object>();

        data.forEach((elev) => {
            if (elev.klassetrin === 0) {
                elever0.push(
                    <li key={elev.unilogin}>
                        <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                            className="mr-4"
                        />{" "}
                        {elev.navn} ({elev.klassenavn})
                    </li>
                );
            } else {
                elever19.push(
                    <li key={elev.unilogin}>
                        <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                            className="mr-4"
                        />
                        {elev.navn} ({elev.klassenavn})
                    </li>
                );
            }
        });

        return (
            <div className="mb-5 mt-5">
                {elever0.length > 0 ? (
                    <div>
                        <p className="alert-heading">
                            Elever i børnehaveklassen
                        </p>
                        <p className="alert-text">
                            Du mangler at markere, om eleven rykker op til næste
                            klassetrin:
                        </p>
                        <ul className="nobullet-list">{elever0}</ul>
                        <Link
                            className="nav-link"
                            title="Rediger resultater for elever i 0. klasse"
                            to={MenuSetup[Side.indberetterTrin1].path}
                        >
                            <span>
                                Rediger resultater for elever i 0. klasse
                            </span>
                        </Link>
                    </div>
                ) : null}
                {elever19.length > 0 ? (
                    <div className="mt-5">
                        <p className="alert-heading">Elever i 1. - 9. klasse</p>
                        <div>
                            <p className="alert-text">
                                Det kan være en eller to forskellige ting, du
                                mangler at markere:
                            </p>
                            <ul className="ml-4">
                                <li>
                                    om eleven rykker op til næste klassetrin
                                </li>
                                <li>
                                    om eleven enten er sprogparat, eller om
                                    eleven har gennemført 4 sprogprøveforsøg
                                    uden at blive vurderet sprogparat{" "}
                                </li>
                            </ul>
                            <ul className="nobullet-list">{elever19}</ul>
                            <Link
                                className="nav-link"
                                title="Rediger resultater for elever i 1. - 9. klasse"
                                to={MenuSetup[Side.indberetterTrin2].path}
                            >
                                <span>
                                    Rediger resultater for elever i 1. - 9.
                                    klasse
                                </span>
                            </Link>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    function renderKvittering(data: ManglendeIndberetningsdata[] | undefined) {
        const skoleaar = authenticationStore.getSkoleAar();
        if (data === undefined || data === null) {
            return (
                <div
                    className="alert alert-error"
                    role="alert"
                    aria-label="Beskedbox der viser en fejlmeddelelse"
                >
                    <div className="alert-body">
                        <p className="alert-heading">
                            Undskyld. Der er desværre opstået en fejl.
                        </p>
                        <p className="alert-text">Prøv igen senere.</p>
                    </div>
                </div>
            );
        } else if (data.length > 0) {
            return (
                <div
                    className="alert alert-warning"
                    role="alert"
                    aria-label="Beskedbox der viser en advarsel efter indberetning"
                >
                    <div className="alert-body">
                        <p className="alert-heading">
                            De foreløbige resultater af sprogprøverne i
                            skoleåret {skoleaar}/{skoleaar + 1} er indberettet
                        </p>

                        <p className="alert-text">
                            Du skal indberette igen inden{" "}
                            {indberetningsFristdato}. Det skyldes, at der
                            mangler oplysninger om {data.length} elever.
                        </p>
                        {renderManglendeElever(data)}
                        <p>
                            Oplysningerne om skolens øvrige elever, er gemt. Du
                            kan registrere og redigere i indberetningen indtil
                            den 5. september.
                        </p>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="alert alert-success"
                    role="alert"
                    aria-label="Beskedbox der viser succes efter indberetning"
                >
                    <div className="alert-body">
                        <p className="alert-heading">
                            Resultat af sprogprøverne i skoleåret {skoleaar}/
                            {skoleaar + 1} er indberettet
                        </p>
                        <div className="alert-text">
                            <p>
                                Du kan frem til {indberetningsFristdato} ændre i
                                indberetningen.
                            </p>
                            <p>
                                Alle de oplysninger, du har indberettet er gemt.
                                Hvis du ændrer i indberetningen, er det de
                                nyeste ændringer, der gælder.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <PageLoader dataPresent={manglendeIndberetningsdata} error={error}>
            <Page>
                <div className="container">
                    <div className="page-container">
                        <h1>Kvittering</h1>
                        {renderKvittering(manglendeIndberetningsdata)}
                        <div className="mt-5">
                            <FunktionsLink
                                text={
                                    "Indberettede resultater " +
                                    authenticationStore.getSkoleAar() +
                                    "/" +
                                    (authenticationStore.getSkoleAar() + 1)
                                }
                                icon={["fas", "file-excel"]}
                                onClick={() =>
                                    FileDownloadService(
                                        "institution/" +
                                            authenticationStore.aktivInstitution
                                                .institutionsnummer +
                                            "/indberetninger/" +
                                            authenticationStore.getSkoleAar() +
                                            "/excel"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </Page>
        </PageLoader>
    );
};

export default IndberetningKvittering;
