import axios from "axios";
import { UserManager } from "oidc-client";
import { LogError } from "./ErrorService";
import { useAuthenticationStore } from "../context/store";

const FileDownloadService = <T>(url: string): Promise<T> => {
    const authenticationStore = useAuthenticationStore();
    const callApi = async (): Promise<any> => {
        if (authenticationStore.loggedIn()) {
            return _callApi().catch((error) => {
                LogError(error.stack ? error.stack : error.message);
                throw error;
            });
        } else {
            throw new Error("API call without authenticated user");
        }
    };

    const _callApi = async () => {
        try {
            await axios({
                url: url,
                method: "GET",
                responseType: "blob",
                withCredentials: true,
            }).then((response) => {
                console.log(JSON.stringify(response));
                let fileName = response.headers["filename"];
                let contentType = response.headers["content-type"];

                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: contentType })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } catch (err) {
            throw err;
        }
    };

    return callApi();
};

export default FileDownloadService;
