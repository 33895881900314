import React, { FunctionComponent } from "react";
import Page from "../components/Page";
import "../assets/stylesheets/Overblik.css";
import { Link } from "react-router-dom";
import { MenuSetup, Side } from "../helpers/RouteSetup";
import { indberetningsFristdato } from "../utils/AppConst";
import FunktionsLink from "../components/common/FunktionsLink";
import FileDownloadService from "../services/FileDownloadService";
import { useAuthenticationStore } from "../context/store";

export const BackToStartLink: FunctionComponent = () => {
    return (
        <div className="navbar">
            <div className="navbar-context-actions">
                <Link to={MenuSetup[Side.overblik].path} className="back-link">
                    Tilbage
                </Link>
            </div>
        </div>
    );
};

const Overblik: FunctionComponent = () => {
    const authenticationStore = useAuthenticationStore();
    const skoleaar = authenticationStore.getSkoleAar();

    //const [error, setError] = useState<Error | undefined>(undefined);
    //const [antalUdvalgte, setAntalUdvalgte] = useState<number | undefined>(undefined);
    //const [antalSprogparate, setAntalSprogparate] = useState<number | undefined>(undefined);
    //const [antalIndberetninger, setAntalIndberetninger] = useState<number | undefined>(undefined);

    /*const footerUdpegning = (antal: number | undefined) => {
        if (antal !== undefined && antal > 0) {
            return (
                <div className="card-footer havblaa-4">
                    <h2 className="h4 mt-4 ml-4">{antal}</h2>
                    <h2 className="h4 mb-4 ml-4">Elever er udpeget til sprogprøve</h2>
                </div>
            )
        }
        return null;
    };*/

    /*const footerRegistrering = (antal: number | undefined) => {
        if (antal !== undefined &&  antalUdvalgte !== undefined && antalUdvalgte > 0) {
            return (
                <div className="card-footer antracitgraa-4">
                    <h2 className="h4 mt-4 ml-4">{antal}</h2>
                    <h2 className="h4 mb-4 ml-4">Elever er vurderet sprogparate</h2>
                </div>
            )
        }
        return null;
    };*/

    /*const footerIndberetning = (antal: number | undefined) => {
        if (antal !== undefined && antalUdvalgte !== undefined && antalUdvalgte > 0) {
            return (
                <div className="card-footer groen-4">
                    <h2 className="h4 mt-4 ml-4">{antal}</h2>
                    <h2 className="h4 mb-4 ml-4">Elevresultater er indberettet</h2>
                </div>
            )
        }
        return null;
    };*/

    /*    
    useEffect(() => {
        async function callApi() {
            ApiService<AntalEleverOverblik>(userManager, "institution/" + getInstitution() + '/hentoverblik/' + 2019).then((response) => {
                //setAntalUdvalgte(response.udvalgte);
                //setAntalSprogparate(response.sprogparate);
                //setAntalIndberetninger(response.indberettede);
            }).catch((e) => {
                //setError(e);
            });
        }

        callApi();
    }, [userManager, getInstitution]);
*/

    return (
        // <PageLoader dataPresent={antalUdvalgte} error={error}>
        <Page>
            <div className="container">
                <div className="page-container">
                    <h1>Overblik over skolens registrering og indberetning</h1>
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-4 col-lg-4 mt-6 mt-md-0">
                            <div className="card card-align-height">
                                <div className="card-text">
                                    <h2 className="h4">
                                        1. Udvælg elever der skal til sprogprøve
                                    </h2>
                                    <p>
                                        I skal udvælge de elever, der skal til
                                        sprogprøve i 0. – 9. klasse. Når I har
                                        udvalgt eleverne, er det muligt at
                                        registrere deres resultater. I kan
                                        løbende tilføje flere elever til listen.
                                    </p>
                                    <p>
                                        Hvis I har brugt Sprogprøver.dk til at
                                        registrere resultater for elever i
                                        børnehaveklassen, er det ikke nødvendigt
                                        at udvælge de pågældende elever her.
                                    </p>
                                </div>
                                <div className="action-links card-text card-align-height position-rel mt-8">
                                    <ul className="nobullet-list position-bottom">
                                        <li>
                                            <Link
                                                to={
                                                    MenuSetup[Side.udpegning]
                                                        .path
                                                }
                                                className="icon-link"
                                            >
                                                Udvælg elever i 0. – 9. klasse
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/*footerUdpegning(antalUdvalgte)*/}
                            </div>
                        </div>

                        {/* <div className="col col-12 col-sm-12 col-md-4 col-lg-4 mt-6 mt-md-0">
                                <div className="card card-align-height">
                                    <div className="card-text">
                                        <h2 className="h4">2. Registrer elevernes resultater fra spogprøverne</h2>
                                        <p>Skolens personale kan registrere resultater for de enkelte elever i takt med, at sprogprøverne gennemføres.</p>
                                        <p>Du kan her se registreringerne, og selv indtaste resultater.</p>
                                    </div>
                                    <div className="action-links card-text card-align-height position-rel">
                                        <ul className="nobullet-list position-bottom">
                                            <li>
                                                <a href="google.com" className="icon-link">Vis status</a>
                                            </li>
                                            <li>
                                                <a href="google.com" className="icon-link">Rediger resultater</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {footerRegistrering(antalSprogparate)}
                                </div>
                            </div>*/}

                        <div className="col col-12 col-sm-12 col-md-4 col-lg-4 mt-6 mt-md-0">
                            <div className="card card-align-height">
                                <div
                                    className="card-text"
                                    style={{ paddingBottom: 0 }}
                                >
                                    <h2 className="h4">
                                        2. Indberet resultaterne til Børne- og
                                        Undervisningsministeriet
                                    </h2>
                                    <p>
                                        I skal registrere sprogprøveresultater
                                        for de udvalgte elever i 0. – 9 klasse
                                        her. Hvis I har brugt Sprogprøver.dk
                                        bliver resultaterne fra elever i
                                        børnehaveklassen automatisk vist her.
                                    </p>
                                    <p>
                                        Sidste frist for indberetning er{" "}
                                        {indberetningsFristdato}.
                                    </p>
                                </div>
                                <div className="action-links card-text card-align-height position-rel mt-8">
                                    <ul className="nobullet-list position-bottom">
                                        <li>
                                            <Link
                                                className="nav-link"
                                                title="Indberet resultater"
                                                to={
                                                    MenuSetup[Side.indberetning]
                                                        .path
                                                }
                                            >
                                                <span>Indberet resultater</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <FunktionsLink
                                                text={
                                                    "Indberettede resultater " +
                                                    skoleaar +
                                                    "/" +
                                                    (skoleaar + 1)
                                                }
                                                icon={["fas", "file-excel"]}
                                                onClick={() =>
                                                    FileDownloadService(
                                                        "institution/" +
                                                            authenticationStore
                                                                .aktivInstitution
                                                                .institutionsnummer +
                                                            "/indberetninger/" +
                                                            skoleaar +
                                                            "/excel"
                                                    )
                                                }
                                            />
                                        </li>
                                    </ul>
                                </div>
                                {/*footerIndberetning(antalIndberetninger)*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
        //</PageLoader >
    );
};

export default Overblik;
